import React, { useState, useEffect } from 'react';
import { format as formatDate } from 'date-fns';
import SelectDate from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

export interface DatePickerProps {
  value?: Date;
  onChange: (thisDate: Date) => void;
  format?: string;
  minDate?: Date;
  maxDate?: Date;
}

export const DatePicker = (props: DatePickerProps) => {
  const { value, onChange, format, minDate, maxDate } = props;
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    if (value) {
      setStartDate(value);
    } else {
      setStartDate(new Date());
    }
  }, [value]);

  return (
    <SelectDate
      selected={startDate}
      onChange={(date: Date) => {
        setStartDate(date);
        onChange(date);
      }}
      dateFormat={format || 'dd-MM-yyyy'}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};
